import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 656 672">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform=" ">

<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M186.784027,112.221107 
	C196.722763,109.990570 208.474854,116.263702 213.204849,125.834648 
	C217.882111,135.298904 216.585907,144.362076 209.822250,152.355408 
	C207.025558,155.660568 206.016891,159.040939 206.163147,163.256302 
	C206.417267,170.579315 206.335373,177.918594 206.210358,185.247910 
	C206.161316,188.122894 206.989105,190.421631 209.052811,192.387283 
	C213.028397,196.173965 216.876587,200.097443 220.922455,203.806183 
	C223.656967,206.312836 235.664032,206.834702 238.575531,204.600449 
	C240.672821,202.990982 242.866714,201.236526 242.548752,198.096130 
	C241.269409,185.460327 243.351700,172.808426 242.330414,160.146286 
	C242.076645,156.999802 240.878586,154.964798 239.016235,152.797256 
	C228.969055,141.103561 231.726059,123.763184 244.802353,115.672829 
	C252.929779,110.644333 261.244934,110.623978 269.360596,115.244659 
	C277.256805,119.740402 281.703766,126.335747 281.469818,136.026535 
	C281.305115,142.847122 280.207275,148.912827 275.068237,153.719574 
	C273.314819,155.359619 273.037842,157.399139 273.046936,159.642715 
	C273.096985,171.972885 273.131836,184.303436 273.081146,196.633484 
	C273.068298,199.764618 273.703491,202.320877 276.161957,204.642685 
	C279.382629,207.684357 281.321167,211.665497 281.234924,216.225952 
	C281.183228,218.960892 282.537659,220.424286 284.566620,221.864120 
	C290.795746,226.284637 296.948761,230.814178 303.072479,235.380295 
	C304.691498,236.587509 305.965485,238.141190 308.245026,236.430313 
	C310.600006,234.662857 314.284515,234.554840 314.034576,230.033157 
	C313.667297,223.387909 313.887878,216.708054 313.934814,210.043503 
	C313.946442,208.394547 314.045624,206.691757 314.469910,205.111832 
	C315.283752,202.081421 317.084381,200.077820 320.587158,200.543930 
	C323.815704,200.973526 325.111359,203.170074 325.253143,206.107483 
	C325.573975,212.754868 325.730835,219.410080 326.027191,226.058853 
	C326.104797,227.800613 325.633728,230.008514 328.071167,230.609436 
	C331.486023,231.451324 332.939270,230.047241 332.916504,226.455383 
	C332.821503,211.459656 332.887177,196.462936 332.887054,181.466583 
	C332.886993,176.134567 332.804352,170.801620 332.882324,165.470886 
	C332.923767,162.638641 332.058350,161.114075 328.879456,161.225845 
	C325.827271,161.333160 325.441101,162.992142 325.470398,165.498947 
	C325.534576,170.990509 325.402222,176.484543 325.336853,181.977417 
	C325.290253,185.893188 323.424042,188.631592 319.439056,188.576096 
	C315.569916,188.522202 314.118103,185.323410 314.003662,181.970047 
	C313.753723,174.645981 313.789307,167.309723 313.834717,159.979233 
	C313.852325,157.137421 312.951538,154.926712 310.951630,152.847168 
	C303.686096,145.292374 303.903717,135.980270 306.822357,127.145477 
	C309.973267,117.607536 320.226105,111.285988 329.853668,111.821373 
	C339.328430,112.348267 346.741028,116.973808 350.990570,125.515633 
	C355.425232,134.429520 354.767731,143.513275 348.547668,151.307205 
	C345.275665,155.407074 344.098328,159.359573 344.148651,164.425888 
	C344.360443,185.752228 344.234955,207.081787 344.280365,228.410080 
	C344.285126,230.643707 343.568604,233.402710 346.503265,234.435974 
	C349.639740,235.540268 352.385712,238.447495 356.303406,234.981094 
	C361.642670,230.256866 368.629547,227.143280 373.192139,221.856567 
	C378.860535,215.288559 380.250153,205.863129 385.766083,198.884277 
	C385.751526,185.387741 385.721100,171.891174 385.735962,158.394669 
	C385.738892,155.724091 383.759308,154.287796 382.322632,152.511185 
	C375.836823,144.490738 374.564972,135.533676 378.810486,126.334557 
	C383.227509,116.763847 391.231384,111.409302 401.860443,111.862106 
	C411.754578,112.283592 419.479645,117.622993 423.306671,126.678185 
	C426.981079,135.372314 426.632721,144.433640 419.867645,151.965744 
	C417.103912,155.042892 415.994781,158.157013 416.076447,162.251831 
	C416.305664,173.744644 416.115356,185.245163 416.188293,196.742020 
	C416.227753,202.965073 419.145966,204.630920 425.039246,205.403351 
	C434.001892,206.578079 439.292328,202.140335 445.189911,196.483414 
	C450.756470,191.144028 453.580566,185.699249 452.830750,178.089920 
	C452.423859,173.961121 452.629242,169.765442 452.667755,165.600937 
	C452.718353,160.126450 451.450256,155.483902 447.653870,151.003525 
	C438.676422,140.408676 442.211121,119.897881 458.487335,113.315636 
	C467.919647,109.501137 482.031799,114.339241 487.526215,122.588211 
	C493.677490,131.823303 493.401764,142.966766 485.857391,152.063553 
	C483.825714,154.513275 482.939026,157.099213 482.979431,160.281876 
	C483.114868,170.944443 482.998016,181.610016 483.103699,192.273209 
	C483.166595,198.624237 481.038544,203.843460 476.416931,208.313461 
	C469.126465,215.364792 462.126953,222.716324 454.970337,229.906982 
	C450.946594,233.949829 446.303894,236.571594 440.331940,236.229111 
	C437.508392,236.067184 434.662323,236.312317 431.836670,236.170197 
	C425.316498,235.842224 419.358582,236.052368 413.899292,241.067703 
	C409.726044,244.901550 403.675262,245.840408 397.834808,244.779343 
	C395.456757,244.347290 393.591888,244.891586 391.792511,246.274429 
	C385.859985,250.833649 379.963593,255.441483 373.966644,259.914429 
	C371.742950,261.573029 371.618652,263.455750 372.013184,266.024139 
	C374.843231,284.446686 369.017242,299.344086 353.786377,310.468201 
	C339.213928,321.111420 314.864105,319.897705 301.313995,307.868011 
	C288.826172,296.781464 284.068329,283.014313 286.499786,266.671448 
	C287.014221,263.213623 286.661682,260.757812 283.311890,258.674316 
	C278.952087,255.962631 275.022400,252.552170 270.949402,249.388626 
	C267.136322,246.426956 263.672943,243.927139 257.987793,244.934540 
	C251.747131,246.040375 245.836517,243.040359 241.081345,238.879547 
	C238.708618,236.803421 236.390106,236.105972 233.434311,236.181183 
	C228.438583,236.308258 223.421310,235.972672 218.442795,236.293152 
	C212.403931,236.681900 207.757889,234.051376 203.755127,230.097565 
	C196.182983,222.618042 188.955719,214.784927 181.279938,207.416656 
	C177.323257,203.618500 175.862701,199.209488 175.855194,194.010910 
	C175.839294,183.013641 175.823364,172.016357 175.792023,161.019119 
	C175.785385,158.694183 175.885895,156.383392 174.064072,154.464127 
	C159.625412,139.253204 167.847382,116.937988 186.784027,112.221107 
M350.130676,298.622711 
	C362.350372,289.306000 364.645538,270.203766 356.685394,257.645264 
	C349.349670,246.071884 335.993134,239.998840 322.736267,242.953201 
	C307.793335,246.283325 300.017487,256.513367 297.549194,269.438080 
	C295.014130,282.712585 301.913483,295.533875 314.005249,302.573273 
	C324.341248,308.590515 339.347504,307.117462 350.130676,298.622711 
M187.125336,184.482681 
	C187.146255,187.315140 187.339096,190.159637 187.141937,192.976822 
	C186.924805,196.079620 188.078186,198.425568 190.216949,200.522537 
	C197.582520,207.744080 204.906662,215.007874 212.244125,222.258057 
	C213.431717,223.431519 214.558823,224.686081 216.434921,224.680130 
	C220.766495,224.666382 225.098160,224.678696 229.429703,224.659500 
	C232.067078,224.647812 232.744171,223.046661 232.768173,220.711060 
	C232.794495,218.150055 231.576202,217.076675 229.131073,217.074249 
	C226.799026,217.071945 224.466934,217.024750 222.134995,216.987854 
	C218.815689,216.935349 216.000000,215.772644 213.665649,213.351273 
	C208.587021,208.083328 203.545776,202.773438 198.301880,197.673325 
	C195.832138,195.271286 194.795639,192.629761 194.851715,189.235626 
	C194.972855,181.906082 194.932037,174.572174 194.836060,167.241638 
	C194.801529,164.605331 195.804001,161.307968 191.119354,161.212463 
	C186.516983,161.118637 187.177155,164.213058 187.158875,166.992981 
	C187.122711,172.491470 187.132950,177.990265 187.125336,184.482681 
M459.796844,208.313995 
	C462.087982,206.142181 464.418091,204.009705 466.659576,201.787781 
	C469.025879,199.442123 471.105408,197.110474 471.020203,193.271072 
	C470.817444,184.133453 471.022064,174.987015 471.042877,165.844101 
	C471.048492,163.363968 470.822876,161.129608 467.382507,161.222565 
	C464.200806,161.308502 464.131378,163.388275 464.138580,165.676559 
	C464.152710,170.165176 463.776459,174.690323 464.218719,179.134964 
	C465.143188,188.425537 463.739594,196.647278 455.522583,202.449722 
	C454.997894,202.820221 454.690552,203.483521 454.212585,203.937393 
	C447.248840,210.549698 441.317322,219.144394 429.621246,216.692230 
	C428.878479,216.536499 427.996094,216.962082 427.196289,217.181473 
	C425.604950,217.617981 425.299042,218.882370 425.309143,220.295197 
	C425.319733,221.779312 425.160950,223.321518 426.751984,224.258575 
	C429.431671,225.836853 443.543335,224.467087 445.900696,222.178848 
	C450.427429,217.784882 454.815704,213.248230 459.796844,208.313995 
M388.620850,225.385254 
	C389.375214,226.471588 390.065857,227.609375 390.894562,228.635696 
	C395.496033,234.334625 401.560730,235.260147 408.120850,231.306122 
	C413.493713,228.067749 415.664856,220.634262 412.986908,214.645935 
	C410.519653,209.128815 403.711548,206.135529 397.507111,207.840134 
	C390.086182,209.878952 387.423889,214.763763 388.620850,225.385254 
M393.943848,125.291740 
	C388.398743,129.760681 386.570160,135.027100 388.483551,141.017929 
	C389.981049,145.706604 394.988251,149.427414 400.209808,149.731613 
	C405.164764,150.020294 410.551971,146.764877 412.719147,142.172287 
	C414.840363,137.677261 414.099976,130.998657 410.739655,127.658485 
	C406.080994,123.027687 400.548645,122.586311 393.943848,125.291740 
M253.044861,208.373566 
	C252.281052,208.697891 251.461258,208.931107 250.762100,209.360641 
	C243.950180,213.545639 242.204956,221.572952 246.722366,227.805435 
	C251.648285,234.601532 259.477875,235.799576 265.790070,230.723114 
	C270.210419,227.168106 271.681183,220.540970 269.309662,214.863953 
	C266.970337,209.263977 261.517914,206.925537 253.044861,208.373566 
M473.983765,147.642761 
	C481.443787,142.103546 482.750458,135.267776 477.469788,128.445709 
	C474.368866,124.439621 470.167236,122.913132 465.140625,123.866631 
	C460.065125,124.829414 456.229279,127.115204 454.916779,132.622513 
	C453.702637,137.717026 454.466125,142.317123 458.247925,145.912827 
	C462.637024,150.085953 467.797852,150.967041 473.983765,147.642761 
M179.742584,130.497696 
	C179.335312,131.407272 178.816742,132.284256 178.539398,133.231842 
	C177.077744,138.225937 179.574631,144.803375 183.974854,147.702759 
	C188.641403,150.777649 194.791565,150.416977 199.317810,146.802948 
	C203.711868,143.294464 205.031769,137.336090 203.423752,132.094086 
	C200.550461,122.727425 184.993362,120.708076 179.742584,130.497696 
M245.491013,141.787430 
	C249.555420,148.990875 255.840942,151.565750 262.407013,148.717056 
	C268.949371,145.878677 271.724884,139.145889 269.471680,131.579880 
	C267.664795,125.512596 260.241364,122.399620 252.917953,124.638168 
	C246.402420,126.629776 242.998489,133.850922 245.491013,141.787430 
M326.130737,124.300674 
	C317.587463,126.808556 314.339203,133.632812 317.609100,142.203720 
	C320.182892,148.950012 327.931305,151.526855 335.164612,148.042053 
	C340.172302,145.629486 343.397614,138.988251 342.040009,133.884979 
	C340.088135,126.547798 335.521576,123.610634 326.130737,124.300674 
M261.477570,193.708527 
	C261.543884,184.433960 261.601624,175.159317 261.682220,165.884872 
	C261.703735,163.409302 261.630402,161.263733 258.164917,161.205902 
	C254.774277,161.149292 253.925827,162.775375 253.963257,165.795334 
	C254.065887,174.078094 253.932938,182.363754 254.032562,190.646591 
	C254.099396,196.203522 255.838745,197.077499 261.477570,193.708527 
M397.309143,171.552826 
	C397.313202,178.382812 397.307556,185.212830 397.327301,192.042770 
	C397.333252,194.098526 397.992554,195.613312 400.415039,195.727783 
	C402.660706,195.833893 403.957977,194.918976 403.963257,192.609589 
	C403.984863,183.115509 403.990906,173.621216 403.946930,164.127258 
	C403.937256,162.040726 402.611206,161.290024 400.643646,161.268127 
	C398.667480,161.246170 397.407379,161.929611 397.373627,164.081558 
	C397.339661,166.246765 397.333130,168.412369 397.309143,171.552826 
M287.341034,247.080475 
	C288.747406,248.513977 290.304443,249.712494 292.591003,250.418457 
	C293.900238,248.875000 295.695557,247.634842 296.169891,245.095612 
	C292.684174,241.560440 288.171234,239.263000 284.487579,235.987976 
	C280.050720,232.043335 279.189392,231.142075 275.800659,238.230270 
	C278.858185,241.776596 283.177551,243.755478 287.341034,247.080475 
M366.412231,241.821457 
	C365.523865,242.560181 364.596375,243.257172 363.755280,244.046234 
	C361.972321,245.718948 363.465668,247.036667 364.338715,248.351227 
	C365.185822,249.626648 366.310272,251.030319 367.947601,249.847397 
	C373.201294,246.051758 378.331207,242.084778 383.291687,238.347534 
	C380.563599,232.873032 377.763458,232.532883 374.068848,236.029602 
	C371.925354,238.058273 369.346008,239.626450 366.412231,241.821457 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M453.424316,346.594849 
	C458.114532,351.177948 462.001190,355.533600 462.363342,362.472382 
	C462.762146,370.113617 458.681824,374.964905 453.937866,379.749573 
	C447.271057,386.473694 440.501495,393.096222 433.856018,399.841156 
	C424.469971,409.367676 414.107697,409.543243 404.482605,400.225586 
	C401.853577,397.680511 399.274445,395.083832 396.639191,392.545349 
	C393.912628,389.918762 390.790680,389.695251 387.395020,391.080841 
	C382.010376,393.278046 376.760071,395.773865 371.221741,397.639130 
	C367.489319,398.896210 365.333496,401.856537 365.353821,406.063019 
	C365.371552,409.728027 365.071106,413.411926 365.342896,417.055237 
	C366.296417,429.837097 357.496307,436.282654 346.570465,436.960358 
	C334.951904,437.681000 323.246277,437.434052 311.595551,437.069672 
	C301.013428,436.738739 293.715302,428.903809 293.056702,417.852020 
	C292.799347,413.533752 292.861237,409.189484 292.943237,404.860168 
	C293.009766,401.349731 291.165344,398.802704 288.228363,397.928741 
	C282.139832,396.116943 276.563416,393.188049 270.711273,390.884796 
	C267.958221,389.801208 265.232056,390.026459 262.905121,392.429810 
	C259.903900,395.529602 256.635468,398.368866 253.585449,401.423523 
	C246.190063,408.830170 234.798569,409.287842 227.519669,402.155487 
	C218.732819,393.545654 210.080429,384.796478 201.470398,376.009094 
	C195.410080,369.823975 195.140732,358.354492 200.880554,351.774078 
	C203.827591,348.395477 207.031738,345.239014 210.158707,342.020081 
	C213.192780,338.896729 213.646515,335.407959 211.976379,331.454865 
	C209.716782,326.106628 207.693497,320.655090 205.316132,315.361359 
	C203.942947,312.303711 201.430664,310.407471 197.880447,310.372284 
	C193.548904,310.329285 189.216431,310.372040 184.885040,310.320801 
	C174.614456,310.199249 166.874847,302.604584 166.795532,292.387329 
	C166.703690,280.558594 166.675797,268.727264 166.819244,256.899445 
	C166.933548,247.474152 174.780777,238.769699 183.574142,239.021759 
	C190.267258,239.213638 196.367828,242.316910 202.282059,245.395050 
	C203.379562,245.966278 203.785126,247.107391 204.047302,248.287750 
	C205.048340,252.794647 201.573669,256.193451 196.960480,254.939804 
	C193.598038,254.026062 190.374146,252.612946 187.061661,251.502441 
	C182.310623,249.909683 178.512604,251.987259 178.303818,257.014496 
	C177.813202,268.827820 177.788803,280.668854 178.573105,292.475586 
	C178.853638,296.698486 181.331833,299.041107 185.898499,298.887085 
	C190.224792,298.741119 194.567337,298.738190 198.891129,298.931824 
	C206.338272,299.265381 212.034149,302.756897 215.184708,309.509064 
	C218.272537,316.126862 220.708679,323.045441 223.592316,329.762665 
	C226.900955,337.469879 223.927567,343.691345 219.066925,349.395782 
	C216.487717,352.422699 213.428833,355.035889 210.754715,357.988220 
	C207.234573,361.874573 207.285156,365.677338 210.980957,369.469788 
	C218.413361,377.096466 225.880905,384.692413 233.484863,392.147095 
	C238.241577,396.810455 240.935638,396.878174 245.975052,392.601837 
	C249.137390,389.918365 252.000687,386.881195 254.983063,383.987152 
	C260.781647,378.360168 267.659027,377.449768 274.881927,380.156830 
	C281.563049,382.660828 288.117371,385.594910 294.484314,388.819733 
	C300.839691,392.038727 304.772705,397.058502 304.248962,404.773590 
	C303.990112,408.586700 304.132996,412.438293 304.281738,416.265167 
	C304.480225,421.370911 307.275909,424.616394 312.251953,424.716034 
	C323.742737,424.946198 335.241974,424.901550 346.735565,424.771973 
	C351.281189,424.720764 353.688629,422.154846 353.838318,417.489197 
	C353.993011,412.666595 354.075043,407.823334 354.607178,403.035583 
	C355.357361,396.286530 358.805695,391.217102 365.093109,388.327057 
	C371.140961,385.547119 377.159668,382.689575 383.302673,380.134247 
	C391.737518,376.625549 399.827362,379.175812 407.748291,387.480774 
	C409.466827,389.282654 411.158844,391.137817 413.063263,392.728760 
	C417.308289,396.275085 420.511719,396.473999 424.515594,392.634918 
	C432.797760,384.693634 440.879333,376.533569 448.811310,368.241455 
	C451.938446,364.972351 451.447021,361.224487 448.358826,357.954529 
	C445.388428,354.809296 442.199158,351.861237 439.352417,348.610779 
	C433.994995,342.493561 432.032410,335.711639 435.658691,327.875488 
	C438.521790,321.688599 441.011017,315.327850 443.904572,309.156067 
	C446.997437,302.559143 452.260284,298.955444 459.663025,298.815735 
	C463.660522,298.740295 467.660889,298.821899 471.658691,298.755280 
	C477.858459,298.651947 480.608704,295.807983 480.722046,289.478973 
	C480.742920,288.312988 480.743134,287.146606 480.750336,285.980408 
	C480.770111,282.774384 482.026947,280.180389 485.407471,279.938751 
	C488.736023,279.700806 491.341339,281.671021 491.947632,284.967896 
	C494.507294,298.886871 485.690735,311.304260 472.649261,310.283112 
	C469.006927,309.997955 465.314117,310.412811 461.658356,310.229645 
	C456.905151,309.991455 453.931671,312.386169 452.672577,316.571350 
	C451.319153,321.070160 449.377502,325.274841 447.503479,329.537781 
	C444.416779,336.559357 444.652985,337.693817 450.003601,343.162598 
	C451.051697,344.233856 452.097626,345.307251 453.424316,346.594849 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M269.907959,284.112518 
	C275.746613,313.896545 297.823547,333.663574 327.732727,334.297363 
	C352.310669,334.818207 370.628143,323.195709 382.550110,301.553833 
	C386.251617,294.834534 388.012268,287.632019 388.827179,280.154175 
	C389.555450,273.471497 392.140900,269.199585 399.133667,266.640991 
	C422.852478,257.962555 446.262177,248.442245 469.833740,239.357117 
	C479.139252,235.770493 490.900787,244.388794 492.100006,255.636169 
	C492.397156,258.423218 492.372986,261.349030 491.857910,264.091064 
	C491.246399,267.346191 489.035431,269.396271 485.452271,269.142914 
	C481.811523,268.885468 480.902069,266.289886 480.776489,263.195129 
	C480.715729,261.697418 480.761597,260.195740 480.732208,258.696381 
	C480.599304,251.916321 477.026855,249.269699 470.800659,251.558243 
	C458.317444,256.146637 445.901367,260.917999 433.409607,265.482544 
	C430.295013,266.620636 429.029449,268.428619 429.055145,271.792358 
	C429.199066,290.642548 424.800781,308.330536 415.329346,324.735046 
	C413.151276,328.507385 410.622589,331.988495 408.043091,335.454742 
	C405.809814,338.455750 402.973785,339.282532 399.835052,336.932495 
	C396.739410,334.614685 397.309082,331.801941 399.313965,329.059174 
	C404.440338,322.046173 409.098480,314.668762 412.019470,306.528137 
	C415.819153,295.938782 418.673645,285.027435 417.420624,273.516083 
	C408.267365,273.854462 400.159576,277.474457 398.892365,286.679047 
	C392.984070,329.594696 345.360168,356.918793 304.865356,341.428131 
	C281.587006,332.523407 266.895508,315.878510 259.843445,292.198822 
	C259.512054,291.086090 259.043030,289.870270 259.195129,288.780182 
	C260.601715,278.699432 253.285431,276.255829 246.110550,273.712341 
	C242.837097,272.551971 241.166595,273.464539 241.071716,277.301971 
	C240.754761,290.121643 244.253036,302.071686 249.901657,313.282043 
	C258.820740,330.982910 271.915741,344.934021 289.885101,353.745117 
	C305.159607,361.234863 321.337830,363.849182 338.350311,362.260864 
	C354.897888,360.715973 369.521606,354.672028 382.875580,345.066803 
	C385.549988,343.143158 388.617279,341.363464 391.106354,345.045624 
	C393.426727,348.478241 392.501587,351.505157 389.045898,354.026459 
	C363.401093,372.737244 335.104645,378.523956 304.390289,370.720337 
	C275.304504,363.330505 254.138199,345.341553 240.236862,319.140869 
	C232.921524,305.353241 229.319656,290.187042 229.711334,274.418152 
	C229.838409,269.302490 228.521942,266.178314 223.249344,265.081024 
	C221.170837,264.648468 219.165802,263.617584 217.275055,262.587860 
	C214.706970,261.189331 213.051071,258.956390 214.328094,256.002899 
	C215.628220,252.996002 218.096161,251.108963 221.570053,252.422119 
	C235.242874,257.590485 248.925613,262.743195 262.470947,268.231689 
	C266.394836,269.821625 268.236969,273.623413 268.960388,277.776642 
	C269.302673,279.741547 269.524719,281.727417 269.907959,284.112518 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M338.416748,451.430664 
	C345.405396,444.418518 353.609711,441.611359 363.083282,441.694427 
	C377.577850,441.821564 392.075989,441.766296 406.569855,441.578430 
	C409.759613,441.537079 411.217682,442.410370 411.199127,445.708588 
	C411.110077,461.537506 411.464752,477.378357 410.962585,493.192780 
	C410.456329,509.135010 398.146362,521.524963 382.053406,522.101013 
	C366.244171,522.666931 350.399323,522.218140 334.570282,522.265991 
	C331.346100,522.275757 330.343018,520.917664 330.390594,517.662048 
	C330.597076,503.531342 330.787048,489.390900 330.470306,475.265472 
	C330.270416,466.353210 332.216309,458.448944 338.416748,451.430664 
M348.409973,468.656250 
	C346.833923,463.500580 349.030457,458.566589 349.605560,453.524902 
	C348.003784,453.006042 347.105469,453.765930 346.240448,454.519440 
	C334.403870,464.829681 334.646301,480.074554 345.509399,490.417053 
	C353.199585,497.738770 362.944000,498.226746 368.363037,492.518311 
	C377.899597,499.612915 377.714417,503.695496 367.376495,508.421539 
	C366.334717,504.811279 363.678192,504.151245 360.188538,503.975006 
	C353.796539,503.652191 347.784302,501.820923 342.631317,497.677521 
	C341.474731,496.747498 340.401154,495.175018 338.435974,495.591187 
	C336.844757,500.365997 337.682159,505.231964 337.363831,510.014160 
	C337.109924,513.829285 338.921021,514.929810 342.424225,514.880676 
	C351.073273,514.759094 359.725647,514.897522 368.375885,514.828186 
	C377.184875,514.757568 384.331146,508.712738 384.659729,501.193359 
	C385.025360,492.825287 378.583435,486.020874 369.291168,485.438019 
	C358.678284,484.772369 352.224823,479.032593 348.409973,468.656250 
M377.899170,478.946350 
	C384.999237,481.950378 387.037537,474.253510 392.318787,472.879761 
	C385.630096,465.902191 379.121765,465.693939 373.536743,471.909210 
	C369.581512,470.571594 367.873566,466.012085 363.677734,464.593933 
	C361.994263,467.839661 359.930267,469.915588 356.070374,466.042999 
	C357.454437,473.081635 361.247772,476.522827 367.217041,477.599670 
	C370.481262,478.188538 373.808990,478.425446 377.899170,478.946350 
M388.084320,460.561066 
	C389.028107,460.745300 389.971893,460.929565 390.904358,461.111633 
	C391.609344,459.193451 389.331848,459.478851 389.268890,458.108795 
	C389.698669,456.558899 392.907288,455.714661 391.084839,453.331116 
	C389.699921,451.519836 387.380768,451.691711 385.559235,452.171509 
	C382.119110,453.077667 379.422913,455.282959 376.845673,458.209351 
	C380.460388,460.530853 384.196533,459.108185 388.084320,460.561066 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M459.741058,445.618622 
	C468.938873,443.951141 477.849182,444.978088 487.452667,444.609131 
	C486.442474,450.756042 489.578094,456.834564 483.947784,461.760376 
	C482.107635,463.370270 482.336121,466.359100 482.341248,468.892700 
	C482.366699,481.544983 482.001526,494.209503 482.436859,506.846832 
	C483.063507,525.036865 469.995270,532.610107 456.371979,533.274231 
	C446.178833,533.771118 436.303131,532.795044 429.235229,523.912720 
	C423.948242,517.268616 424.372803,509.209595 424.056519,501.520935 
	C423.591553,490.218445 423.874573,478.884644 423.867615,467.564178 
	C423.865723,464.487305 423.576630,461.749939 420.638672,459.699280 
	C418.603027,458.278442 418.050934,447.870270 419.463745,445.901367 
	C420.373871,444.633026 421.737915,444.659058 423.064026,444.657166 
	C430.721985,444.646301 438.380493,444.713531 446.037567,444.634399 
	C448.930695,444.604492 450.443390,445.658264 450.257660,448.702850 
	C449.982941,453.205597 451.347778,457.902710 447.285431,461.772583 
	C445.407806,463.561279 445.487213,466.716614 445.467621,469.380676 
	C445.385468,480.533966 445.250824,491.689911 445.407623,502.841034 
	C445.520905,510.895050 448.220123,514.416504 453.874939,514.805481 
	C459.384125,515.184448 463.298706,512.093018 463.484924,505.733643 
	C463.884247,492.095917 463.649323,478.438782 463.583801,464.790009 
	C463.578583,463.702820 463.204346,462.206635 462.443695,461.599579 
	C456.922607,457.193115 458.946320,451.457153 459.741058,445.618622 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M265.297791,514.213989 
	C263.107239,507.532104 263.678009,501.015808 263.658356,494.553284 
	C263.625519,483.741241 263.545013,472.927155 263.707184,462.117310 
	C263.867462,451.433075 269.041199,445.048218 279.465881,442.725555 
	C287.677948,440.895844 296.015839,440.984253 304.201050,442.826019 
	C314.503723,445.144165 319.296478,451.441040 319.337616,462.097351 
	C319.388977,475.404388 319.414307,488.711853 319.372375,502.018890 
	C319.322815,517.738220 314.652710,523.230103 299.163330,525.734741 
	C292.356415,526.835571 285.528137,526.559814 278.870178,524.811279 
	C273.069092,523.287781 268.178558,520.288452 265.297791,514.213989 
M283.365204,468.066498 
	C283.364319,478.550232 283.292816,489.034576 283.389618,499.517426 
	C283.445648,505.588104 286.868744,509.610199 291.569489,509.519745 
	C296.622437,509.422485 299.630005,505.855042 299.673676,499.572998 
	C299.745422,489.256317 299.669769,478.938629 299.688660,468.621460 
	C299.692352,466.594788 299.378540,464.652344 298.650696,462.765503 
	C297.590424,460.016937 295.690338,458.454620 292.667511,458.287689 
	C289.631561,458.120026 286.929291,458.796143 285.413300,461.621002 
	C284.487976,463.345215 284.052826,465.332520 283.365204,468.066498 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M137.704102,510.045227 
	C140.242233,513.295105 143.150177,514.309509 146.763031,513.109741 
	C150.415359,511.896881 152.337967,508.841492 152.419800,505.544434 
	C152.750153,492.233826 152.603088,478.911041 152.578934,465.592865 
	C152.577515,464.811646 152.352585,463.693512 151.816223,463.303467 
	C147.155899,459.914398 148.917084,454.969177 148.542770,450.535126 
	C148.320786,447.905792 149.627487,446.920837 152.077927,446.932800 
	C158.238235,446.962860 164.398849,446.990204 170.559006,446.955933 
	C173.378479,446.940247 174.383881,448.399261 174.305862,451.027679 
	C174.180801,455.241821 175.239075,459.609558 171.160721,462.963043 
	C169.454468,464.366028 170.123627,467.041107 170.058075,469.181335 
	C169.650604,482.484192 170.213562,495.787964 169.528534,509.108307 
	C169.088043,517.673706 165.195175,523.205261 158.103180,526.963196 
	C147.377808,532.646423 127.709381,530.794373 121.042221,521.734924 
	C116.783730,515.948425 116.268700,509.408356 116.091866,502.816193 
	C115.788353,491.501892 115.966522,480.174103 115.985161,468.852051 
	C115.990219,465.779053 115.288704,463.299164 113.132301,460.723206 
	C110.155258,457.166962 111.571556,452.500519 112.192314,448.312531 
	C112.411774,446.831970 114.295082,446.939789 115.608047,446.933044 
	C122.267906,446.898773 128.929062,446.976807 135.587524,446.872192 
	C139.002182,446.818542 140.407974,448.287354 140.275909,451.683197 
	C140.123230,455.609375 141.016922,459.701202 137.337265,462.740906 
	C136.116684,463.749207 136.254318,465.477051 136.254868,466.977264 
	C136.259583,479.631073 136.287888,492.284851 136.290131,504.938660 
	C136.290421,506.646027 136.658859,508.235199 137.704102,510.045227 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M551.534058,447.454620 
	C560.346069,458.160492 559.916077,466.177612 549.775452,479.827179 
	C555.917114,483.549744 560.163696,488.728424 561.863831,495.913849 
	C565.547241,511.481384 554.636719,525.984680 538.674866,526.278992 
	C529.345215,526.451050 520.008118,526.396545 510.676971,526.263733 
	C502.780853,526.151367 499.652496,522.826660 499.620911,514.993408 
	C499.531555,492.834106 499.357483,470.674622 499.397797,448.515594 
	C499.406616,443.661804 503.507935,441.940521 507.257721,441.811859 
	C518.242249,441.435120 529.253357,441.561310 540.245850,441.828461 
	C544.536499,441.932770 548.092407,444.383606 551.534058,447.454620 
M535.876892,469.553101 
	C539.586243,466.186646 541.794922,462.431244 539.540466,457.230316 
	C537.281494,452.018951 533.448669,449.415497 527.461670,449.361755 
	C523.802673,449.328918 520.132080,449.532562 516.486511,449.308258 
	C511.405487,448.995667 508.949615,451.536682 508.736298,456.264496 
	C507.971588,473.212341 508.306091,490.170258 508.546509,507.124969 
	C508.550049,507.375854 508.933075,507.621368 509.607056,508.433228 
	C513.363647,505.269073 516.780151,500.319824 522.815247,503.195282 
	C524.017944,504.760376 522.390076,507.332153 524.913818,508.434967 
	C529.763306,510.554108 538.207214,507.468048 540.492371,502.643158 
	C544.030823,495.171997 539.870361,487.312988 530.790771,485.785767 
	C526.801575,485.114746 525.606018,481.424683 522.253967,480.151123 
	C520.818298,479.605713 521.422791,475.803925 521.959656,473.555908 
	C522.547180,471.095856 524.834229,471.722717 526.546021,471.725769 
	C529.583069,471.731232 532.533447,471.549194 535.876892,469.553101 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M221.245087,495.476776 
	C216.837982,495.517883 215.106308,493.683624 215.627579,489.608673 
	C215.940506,487.162598 215.265915,484.630157 216.394806,481.430023 
	C223.601532,481.430023 231.037857,481.428619 238.474167,481.430878 
	C240.714874,481.431549 241.225021,482.848816 241.222107,484.742950 
	C241.207260,494.406128 241.195572,504.069336 241.218246,513.732483 
	C241.223099,515.802429 240.291000,517.191711 238.506210,518.126953 
	C226.460785,524.438599 213.928696,526.181763 200.846146,521.935547 
	C193.602753,519.584534 189.468979,514.310547 188.791855,506.881226 
	C187.353546,491.100128 187.347183,475.265350 188.927399,459.498505 
	C189.680557,451.983673 194.422882,446.990265 201.587845,444.925964 
	C214.096466,441.322113 226.305389,443.018066 238.190765,447.933319 
	C240.290329,448.801605 241.263016,450.238037 241.223969,452.545807 
	C241.147903,457.042816 241.146957,461.543457 241.236679,466.040131 
	C241.291779,468.801666 239.955521,470.031464 237.337097,470.057922 
	C233.672546,470.095001 230.007462,470.082886 226.342606,470.087860 
	C224.220291,470.090729 223.044388,469.207275 222.614807,466.951202 
	C221.744949,462.382965 218.904770,459.896088 214.139160,460.358826 
	C209.116257,460.846558 207.504837,464.551025 207.423477,468.838501 
	C207.233887,478.830933 207.266861,488.830994 207.421188,498.824707 
	C207.471619,502.090088 208.567215,505.142609 212.127640,506.356934 
	C215.175079,507.396240 218.318039,507.296814 220.415573,504.709808 
	C222.519577,502.114807 223.876587,498.993744 221.245087,495.476776 
z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
